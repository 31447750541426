<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableBorrowings.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="tableBorrowings"
        small
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableBorrowings.perPage"
        :current-page="tableBorrowings.currentPage"
        :items="tableBorrowingProvider"
        :fields="tableBorrowings.fields"
        :sort-by.sync="tableBorrowings.sortBy"
        :sort-desc.sync="tableBorrowings.sortDesc"
        :sort-direction="tableBorrowings.sortDirection"
        :filter="tableBorrowings.filter"
        :filter-included-fields="tableBorrowings.filterOn"
      >
        <template #cell(index)="row">
          {{ tableBorrowings.currentPage * tableBorrowings.perPage - tableBorrowings.perPage + (row.index + 1) }}
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              :disabled="state.busy"
              @click="onShowReserveBorrowing(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableBorrowings.perPage"
              :options="tableBorrowings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableBorrowings.currentPage"
            :total-rows="tableBorrowings.totalRows"
            :per-page="tableBorrowings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-borrowings"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="ModalBorrowingTitle"
    >
      <b-row>

        <b-col
          cols="12"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="employee_name">
                  <strong>
                    Employee
                  </strong>
                </label>
                <b-input
                  id="employee_name"
                  v-model="borrowing.employee_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label for="start_date">
                  <strong>
                    Start
                  </strong>
                </label>
                <b-input
                  id="start_date"
                  v-model="borrowing.start_date"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label for="end_date">
                  <strong>
                    End
                  </strong>
                </label>
                <b-input
                  id="end_date"
                  v-model="borrowing.end_date"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="purpose">
                  <strong>
                    Purpose
                  </strong>
                </label>
                <b-textarea
                  id="purpose"
                  v-model="borrowing.purpose"
                  rows="4"
                  max-rows="6"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="borrowing_remarks">
                  <strong>
                    Remarks
                  </strong>
                </label>
                <b-textarea
                  id="borrowing_remarks"
                  v-model="borrowing.remarks"
                  rows="4"
                  max-rows="6"
                  placeholder="no remarks"
                  disabled
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>

        <b-col cols="12 d-none d-md-block">
          <hr>
        </b-col>

        <b-col
          cols="12"
        >
          <b-row>

            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="reservations_search">
                      <strong>
                        Search Item
                      </strong>
                    </label>
                    <b-input
                      id="reservations_search"
                      v-model="tableReservations.filter"
                      type="text"
                      placeholder="search here"
                      debounce="1000"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-table
                    ref="tableReservationBorrowingItems"
                    hover
                    responsive
                    show-empty
                    :per-page="tableReservations.perPage"
                    :current-page="tableReservations.currentPage"
                    :items="tableBorrowingItemsProvider"
                    :fields="tableReservations.fields"
                    :sort-by.sync="tableReservations.sortBy"
                    :sort-desc.sync="tableReservations.sortDesc"
                    :sort-direction="tableReservations.sortDirection"
                    :filter="tableReservations.filter"
                    :filter-included-fields="tableReservations.filterOn"
                  >
                    <template #cell(index)="row">
                      {{ tableReservations.currentPage * tableReservations.perPage - tableReservations.perPage + (row.index + 1) }}
                    </template>

                    <template #cell()="row">
                      <div class="text-nowrap">
                        {{ row.value }}
                      </div>
                    </template>

                    <template #cell(serial_number)="row">
                      <div class="text-nowrap">
                        <strong>
                          {{ row.value }}
                        </strong>
                      </div>
                    </template>

                    <template #cell(status)="row">
                      <div class="text-nowrap">
                        <strong
                          v-if="row.value === 'Pending'"
                          class="text-secondary"
                        >
                          {{ row.value }}
                        </strong>
                        <strong
                          v-if="row.value === 'Reserved'"
                          class="text-info"
                        >
                          {{ row.value }}
                        </strong>
                        <strong
                          v-if="row.value === 'Issued'"
                          class="text-warning"
                        >
                          {{ row.value }}
                        </strong>
                        <strong
                          v-if="row.value === 'Returned'"
                          class="text-success"
                        >
                          {{ row.value }}
                        </strong>
                        <strong
                          v-if="row.value === 'Cancelled'"
                          class="text-danger"
                        >
                          {{ row.value }}
                        </strong>
                      </div>
                    </template>

                    <template #cell(action)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          class="mr-1"
                          :disabled="state.busy"
                          @click="onPreviewBorrowingItem(row.item)"
                        >
                          View
                        </b-button>
                      </div>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-danger my-5">
                        <b-spinner class="align-middle" />
                      </div>
                    </template>

                  </b-table>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                    <b-select
                      v-model="tableReservations.perPage"
                      :options="tableReservations.pageOptions"
                      size="sm"
                    />
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="tableReservations.currentPage"
                    :total-rows="tableReservations.totalRows"
                    :per-page="tableReservations.perPage"
                    first-number
                    last-number
                    pills
                    prev-text="Prev"
                    next-text="Next"
                    aria-controls="table"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >

              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label
                      for="peripherals_search"
                    >
                      <strong>
                        Search Peripherals
                      </strong>
                    </label>
                    <b-input
                      id="peripherals_search"
                      v-model="tableReservationDetails.filter"
                      type="text"
                      placeholder="search here"
                      debounce="1000"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-table
                    ref="tableReservationBorrowingItemDetails"
                    hover
                    responsive
                    show-empty
                    :per-page="tableReservationDetails.perPage"
                    :current-page="tableReservationDetails.currentPage"
                    :items="tableBorrowingItemDetailsProvider"
                    :fields="tableReservationDetails.fields"
                    :sort-by.sync="tableReservationDetails.sortBy"
                    :sort-desc.sync="tableReservationDetails.sortDesc"
                    :sort-direction="tableReservationDetails.sortDirection"
                    :filter="tableReservationDetails.filter"
                    :filter-included-fields="tableReservationDetails.filterOn"
                  >
                    <template #cell(index)="row">
                      {{ tableReservationDetails.currentPage * tableReservationDetails.perPage - tableReservationDetails.perPage + (row.index + 1) }}
                    </template>

                    <template #cell()="row">
                      <div class="text-nowrap">
                        {{ row.value }}
                      </div>
                    </template>

                    <template #cell(serial_number)="row">
                      <div class="text-nowrap">
                        <strong>
                          {{ row.value }}
                        </strong>
                      </div>
                    </template>

                    <template #cell(status)="row">
                      <div class="text-nowrap">
                        <strong
                          v-if="row.value === 'Pending'"
                          class="text-secondary"
                        >
                          {{ row.value }}
                        </strong>
                        <strong
                          v-if="row.value === 'Reserved'"
                          class="text-info"
                        >
                          {{ row.value }}
                        </strong>
                        <strong
                          v-if="row.value === 'Issued'"
                          class="text-warning"
                        >
                          {{ row.value }}
                        </strong>
                        <strong
                          v-if="row.value === 'Returned'"
                          class="text-success"
                        >
                          {{ row.value }}
                        </strong>
                        <strong
                          v-if="row.value === 'Cancelled'"
                          class="text-danger"
                        >
                          {{ row.value }}
                        </strong>
                      </div>
                    </template>

                    <template #cell(action)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          class="mr-1"
                          :disabled="state.busy"
                          @click="onPreviewBorrowingItemDetail(row.item)"
                        >
                          View
                        </b-button>
                      </div>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-danger my-5">
                        <b-spinner class="align-middle" />
                      </div>
                    </template>

                  </b-table>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                    <b-select
                      v-model="tableReservationDetails.perPage"
                      :options="tableReservationDetails.pageOptions"
                      size="sm"
                    />
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="tableReservationDetails.currentPage"
                    :total-rows="tableReservationDetails.totalRows"
                    :per-page="tableReservationDetails.perPage"
                    first-number
                    last-number
                    pills
                    prev-text="Prev"
                    next-text="Next"
                    aria-controls="table"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

      </b-row>
      <template #modal-footer="{ cancel}">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-borrowing-item"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="ModalAssignTitle"
      @cancel="onCloseBorrowingItem"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label>
              <strong>
                Serial Number
              </strong>
            </label>
            <b-input
              id="item"
              v-model="item.serial_number"
              placeholder="no assigned serial number"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              class="col-12 px-0"
              for="template"
            >
              <strong>
                Template
              </strong>
            </label>
            <b-input
              id="template"
              v-model="borrowing_item.template_name"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="item_code">
              <strong>Item Code</strong>
            </label>
            <b-input
              id="item_code"
              v-model="item.item_code"
              placeholder="no assigned item code"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="category_name">
              <strong>Category</strong>
            </label>
            <b-input
              id="category_name"
              v-model="item.category_name"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="item_name">
              <strong>Name</strong>
            </label>
            <b-input
              id="item_name"
              v-model="item.item_name"
              placeholder="no assigned item"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="supplier_name">
              <strong>Supplier</strong>
            </label>
            <b-input
              id="supplier_name"
              v-model="item.supplier_name"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="brand_name">
              <strong>Brand</strong>
            </label>
            <b-input
              id="brand_name"
              v-model="item.brand_name"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="specifications">
              <strong>Specifications</strong>
            </label>
            <b-textarea
              id="specifications"
              v-model="item.specifications"
              rows="8"
              max-rows="12"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="item_remarks">
              <strong>Remarks</strong>
            </label>
            <b-textarea
              id="item_remarks"
              v-model="item.remarks"
              rows="4"
              max-rows="6"
              placeholder="no remarks"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-table
                hover
                responsive
                show-empty
                :items="compositionItemDetailFilter"
                :fields="tableAssigning.compositions.fields"
                :busy="fetching.compositions"
              >

                <template #cell()="row">
                  <div class="text-nowrap">
                    {{ row.value }}
                  </div>
                </template>

                <template #head(item_details_count)="row">
                  <div class="text-nowrap text-center">
                    {{ row.label }}
                  </div>
                </template>

                <template #cell(item_details_count)="row">
                  <div class="text-nowrap text-center">
                    <strong>{{ row.value }}</strong>
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle" />
                  </div>
                </template>

              </b-table>
            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <b-table
                ref="tableAssigningItemDetails"
                hover
                responsive
                show-empty
                :items="list.item_details"
                :fields="tableAssigning.itemDetails.fields"
                :busy="fetching.item_details"
              >
                <template #head(selected)="row">
                  <div
                    v-if="row"
                    class="text-center"
                  >
                    SELECTED
                  </div>
                </template>
                <template #cell(selected)="row">
                  <div class="d-flex justify-content-center align-self-center">
                    <template v-if="row.rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </div>
                </template>

                <template #cell(item_detail_code)="row">
                  <div class="text-nowrap">
                    <strong>{{ row.value }}</strong>
                  </div>
                </template>

                <template #cell()="row">
                  <div class="text-nowrap">
                    {{ row.value }}
                  </div>
                </template>

                <template #cell(category_name)="row">
                  <div class="text-nowrap">
                    <strong class="text-success">{{ row.value }}</strong>
                  </div>
                </template>

                <template #cell(serial_number)="row">
                  <div class="text-nowrap">
                    <strong>{{ row.value }}</strong>
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle" />
                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-borrowing-detail"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="ModalDetailTitle"
      @cancel="onCloseBorrowingItemDetail"
    >

      <b-row>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label>
              <strong>
                Item Detail Code
              </strong>
            </label>
            <b-input
              id="item_detail"
              v-model="item_detail.item_detail_code"
              placeholder="no assigned"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              class="col-12 px-0"
              for="template"
            >
              <strong>
                Template
              </strong>
            </label>
            <b-input
              id="template"
              v-model="borrowing_item_detail.template_name"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="serial_number">
              <strong>Serial Number</strong>
            </label>
            <b-input
              id="serial_number"
              v-model="item_detail.serial_number"
              placeholder="no serial number"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="category_name">
              <strong>Category</strong>
            </label>
            <b-input
              id="category_name"
              v-model="item_detail.category_name"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="item_detail_name">
              <strong>Name</strong>
            </label>
            <b-input
              id="item_detail_name"
              v-model="item_detail.item_detail_name"
              placeholder="no assigned item"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="supplier_name">
              <strong>Supplier</strong>
            </label>
            <b-input
              id="supplier_name"
              v-model="item_detail.supplier_name"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="brand_name">
              <strong>Brand</strong>
            </label>
            <b-input
              id="brand_name"
              v-model="item_detail.brand_name"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="specifications">
              <strong>Specifications</strong>
            </label>
            <b-textarea
              id="specifications"
              v-model="item_detail.specifications"
              rows="8"
              max-rows="12"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="item_detail_remarks">
              <strong>Remarks</strong>
            </label>
            <b-textarea
              id="item_detail_remarks"
              v-model="item_detail.remarks"
              rows="4"
              max-rows="6"
              placeholder="no remarks"
              disabled
            />
          </b-form-group>
        </b-col>

      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AdminBorrowingHistoryService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminBorrowingHistory',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Borrow Histories'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        reserving: false,
        assigning: false
      },
      fetching: {
        templates: false,
        compositions: false,
        items: false,
        item_details: false
      },
      filter: {
        template: 'All'
      },
      list: {
        templates: [],
        compositions: [],
        items: [],
        item_details: []
      },
      selected: {
        template: {
          id: null,
          template_name: null,
          items_count: 0
        },
        item_details: [],
        item: null,
        item_detail: null
      },
      borrowing: {
        id: null,
        employee: null,
        employee_name: null,
        start_date: null,
        end_date: null,
        purpose: null,
        status: 'Pending',
        action_status: 'Pending',
        items: []
      },
      borrowing_item: {
        id: null,
        borrowing_id: null,
        template_id: null,
        template_name: null,
        category_id: null,
        category_name: null,
        borrowing_item_details: []
      },
      borrowing_item_detail: {
        id: null,
        borrowing_id: null,
        template_id: null,
        template_name: null,
        category_id: null,
        category_name: null
      },
      item: {
        id: null,
        item_name: null,
        serial_number: null,
        template: null,
        template_name: null,
        type_name: null,
        category_name: null,
        supplier_name: null,
        brand_name: null,
        status: 'Pending',
        action_status: 'Pending',
        remarks: null
      },
      item_detail: {
        id: null,
        item_name: null,
        serial_number: null,
        template: null,
        template_name: null,
        type_name: null,
        category_name: null,
        supplier_name: null,
        brand_name: null,
        status: 'Pending',
        action_status: 'Pending',
        remarks: null
      },
      tableBorrowings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'employee_name', label: 'employee' },
          { key: 'start_date', formatter: this.dateTimeShortFormatter },
          { key: 'end_date', formatter: this.dateTimeShortFormatter },
          { key: 'status' },
          { key: 'created_at', formatter: this.dateTimeShortFormatter }
        ]
      },
      tableBorrowingItems: {
        compositions: {
          fields: [
            { key: 'category_name', label: 'category' },
            { key: 'item_details_count', label: 'remaining' },
            { key: 'action' }
          ]
        },
        items: {
          fields: [
            { key: 'template_name', label: 'template' },
            { key: 'category_name', label: 'category' },
            { key: 'action' }
          ]
        }
      },
      tableReservations: {
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'action' },
          { key: 'status', label: 'status' },
          { key: 'template_name', label: 'template' },
          { key: 'item_code' },
          { key: 'item_name' },
          { key: 'serial_number' }
        ]
      },
      tableReservationDetails: {
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'action' },
          { key: 'status', label: 'status' },
          { key: 'category_name', label: 'category' },
          { key: 'item_detail_code' },
          { key: 'item_detail_name' },
          { key: 'serial_number' }
        ]
      },
      tableAssigning: {
        compositions: {
          fields: [
            { key: 'category_name', label: 'category' },
            { key: 'item_details_count', label: 'remaining' }
          ]
        },
        itemDetails: {
          selectMode: 'multi',
          fields: [
            { key: 'item_detail_code' },
            { key: 'category_name', label: 'category' },
            { key: 'serial_number' },
            { key: 'employee_name', label: 'employee' }
          ]
        }
      }
    }
  },

  computed: {

    ModalBorrowingTitle () {
      return this.state.reserving ? 'Issue Items' : 'Reserve Items'
    },

    ModalAssignTitle () {
      return `${this.state.assigning ? 'Assign' : 'Assigned'} Item ( ${this.borrowing.employee_name} )`
    },

    ModalDetailTitle () {
      return `${this.state.assigning ? 'Assign' : 'Assigned'} Peripherals ( ${this.borrowing.employee_name} )`
    },

    compositionItemDetailFilter () {
      return this.list.compositions.filter(
        composition => composition.is_parent === 0 && this.borrowing_item.borrowing_item_details.map(
          itemDetail => itemDetail.category_id
        ).includes(composition.category_id)
      )
    },

    compositionItemFilter () {
      return this.list.compositions.filter(composition => composition.is_parent === 1)[0]
    }

  },

  watch: {

    'selected.item' (item) {
      if (item?.id) {
        this.list.item_details = []

        this.item.id = item.id
        this.item.type_name = item.type_name
        this.item.category_name = item.category_name
        this.item.item_code = item.item_code
        this.item.item_name = item.item_name
        this.item.serial_number = item.serial_number
        this.item.brand_name = item.brand_name
        this.item.supplier_name = item.supplier_name
        this.item.specifications = item.specifications

        this.item.item_details = []

        this.getItemDetails(item.id).then(() => {
          this.$refs.tableAssigningItemDetails.selectAllRows()
          this.list.item_details.forEach((itemDetail, row) => {
            const position = this.compositionItemDetailFilter.findIndex(
              composition => composition.category_id === itemDetail.category_id
            )
            if (position < 0) {
              this.$refs.tableAssigningItemDetails.unselectRow(row)
            }
          })
        })
      } else {
        this.list.item_details = []

        this.item.id = null
        this.item.type_name = null
        this.item.category_name = null
        this.item.item_code = null
        this.item.item_name = null
        this.item.serial_number = null
        this.item.brand_name = null
        this.item.supplier_name = null
        this.item.specifications = null
        this.item.item_details = []
      }
    },

    'selected.item_detail' (itemDetail) {
      if (itemDetail?.id) {
        this.item_detail.id = itemDetail.id
        this.item_detail.type_name = itemDetail.type_name
        this.item_detail.category_name = itemDetail.category_name
        this.item_detail.item_detail_code = itemDetail.item_detail_code
        this.item_detail.item_detail_name = itemDetail.item_detail_name
        this.item_detail.serial_number = itemDetail.serial_number
        this.item_detail.brand_name = itemDetail.brand_name
        this.item_detail.supplier_name = itemDetail.supplier_name
        this.item_detail.specifications = itemDetail.specifications
      } else {
        this.item_detail.id = null
        this.item_detail.type_name = null
        this.item_detail.category_name = null
        this.item_detail.item_detail_code = null
        this.item_detail.item_detail_name = null
        this.item_detail.serial_number = null
        this.item_detail.brand_name = null
        this.item_detail.supplier_name = null
        this.item_detail.specifications = null
      }
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    async tableBorrowingProvider (option) {
      return await AdminBorrowingHistoryService.getBorrowings(
        this.objectToUrl({
          page: option.currentPage,
          per_page: option.perPage,
          sort: option.sortBy,
          sort_desc: option.sortDesc,
          filter_text: option.filter
        })
      ).then(({ data }) => {
        this.tableBorrowings.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    BorrowingLabel (status) {
      switch (status) {
        case 'Reserved': {
          return 'Issue'
        }
        case 'Issued': {
          return 'Return'
        }
        default: {
          return 'Reserve'
        }
      }
    },

    BorrowingVariant (status) {
      switch (status) {
        case 'Reserved': {
          return 'warning'
        }
        case 'Issued': {
          return 'success'
        }
        default: {
          return 'secondary'
        }
      }
    },

    onShowReserveBorrowing (borrowing) {
      this.state.reserving = true

      this.borrowing.id = borrowing.id
      this.borrowing.employee = borrowing.employee_id
      this.borrowing.employee_name = borrowing.employee_name
      this.borrowing.start_date = this.dateTimeShortFormatter(borrowing.start_date)
      this.borrowing.end_date = this.dateTimeShortFormatter(borrowing.end_date)
      this.borrowing.purpose = borrowing.purpose
      this.borrowing.status = borrowing.status

      this.$bvModal.show('modal-borrowings')
    },

    // Item Assigning
    async tableBorrowingItemsProvider (option) {
      return await AdminBorrowingHistoryService.getBorrowingItems(
        this.objectToUrl({
          page: option.currentPage,
          per_page: option.perPage,
          sort: option.sortBy,
          sort_desc: option.sortDesc,
          search: option.filter,
          borrowing: this.borrowing.id
        })
      ).then(({ data }) => {
        this.tableReservations.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getTemplateComposition (template) {
      this.fetching.compositions = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTemplateComposition(`template=${template}`).then(({ data }) => {
          this.list.compositions = data.filter(
            composition => (composition.item_details_count > 0 && composition.is_package === 0) || composition.is_parent === 1
          )
          this.fetching.compositions = this.state.busy = false
          resolve(data)
        })
      })
    },

    async getBorrowingReservableItems (template, borrowing, borrowingItem) {
      this.list.items = []
      this.fetching.items = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getBorrowingReservableItems(
          this.objectToUrl({
            template: template,
            borrowing: borrowing,
            borrowing_item: borrowingItem
          })
        ).then(({ data }) => {
          this.list.items = data
          this.fetching.items = this.state.busy = false
          resolve(data)
        })
      })
    },

    async getItemDetails (item) {
      this.list.item_details = []
      this.fetching.item_details = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getItemDetails(`item=${item}&package=0`).then(({ data }) => {
          this.list.compositions.forEach(composition => {
            const position = data.findIndex(
              itemDetail => itemDetail.category_id === composition.category_id
            )
            if (position >= 0) {
              this.list.item_details.push(data[position])
            }
          })
          const itemDetailsNotInCompositions = data.filter(
            itemDetails => !this.list.item_details.map(
              itemDetail => itemDetail.id
            ).includes(itemDetails.id)
          )
          this.list.item_details.push(...itemDetailsNotInCompositions)
          this.fetching.item_details = this.state.busy = false
          resolve(data)
        })
      })
    },

    onPreviewBorrowingItem (borrowingItem, assinging = false) {
      this.state.assigning = assinging

      this.borrowing_item = {
        id: borrowingItem.id,
        borrowing_id: borrowingItem.borrowing_id,
        template_id: borrowingItem.template_id,
        template_name: borrowingItem.template_name,
        category_id: borrowingItem.category_id,
        category_name: borrowingItem.category_name,
        borrowing_item_details: borrowingItem.borrowing_item_details
      }

      const item = borrowingItem.item

      this.item.category_name = borrowingItem.category_name

      if (item) {
        this.item.id = item.id
        this.item.type_name = item.type_name
        this.item.category_name = item.category_name
        this.item.item_code = item.item_code
        this.item.item_name = item.item_name
        this.item.serial_number = item.serial_number
        this.item.brand_name = item.brand_name
        this.item.supplier_name = item.supplier_name
        this.item.specifications = item.specifications
        this.item.status = borrowingItem.status
        this.item.remarks = borrowingItem.remarks
      }

      this.list.item_details = borrowingItem.borrowing_item_details

      this.getTemplateComposition(borrowingItem.template_id).then(() => {
        this.$bvModal.show('modal-borrowing-item')
      })
    },

    onCloseBorrowingItem () {
      this.item.id = null
      this.item.type_name = null
      this.item.category_name = null
      this.item.item_code = null
      this.item.item_name = null
      this.item.serial_number = null
      this.item.brand_name = null
      this.item.supplier_name = null
      this.item.specifications = null

      this.item.item_details = []
      this.list.item_details = []

      this.borrowing_item = {
        id: null,
        borrowing_id: null,
        template_id: null,
        template_name: null,
        category_id: null,
        category_name: null,
        borrowing_item_details: []
      }
    },

    onSelectRowBorrowingItemDetail (item) {
      item.forEach(item => {
        const row = this.list.item_details.findIndex(finder => finder.id === item.id)

        const itemDetail = this.list.item_details[row]

        if (row >= 0 && item.employee_id) {
          if (item.employee_id !== this.borrowing.employee) {
            this.$refs.tableAssigningItemDetails.unselectRow(row)
          }
        }

        if (!this.compositionItemDetailFilter.map(cidf => cidf.category_id).includes(itemDetail.category_id)) {
          this.$refs.tableAssigningItemDetails.unselectRow(row)
        }
      })

      this.item.item_details = item
    },

    // Peripheral Assigning
    async tableBorrowingItemDetailsProvider (option) {
      return await AdminBorrowingHistoryService.getBorrowingItemDetails(
        this.objectToUrl({
          page: option.currentPage,
          per_page: option.perPage,
          sort: option.sortBy,
          sort_desc: option.sortDesc,
          search: option.filter,
          borrowing: this.borrowing.id
        })
      ).then(({ data }) => {
        this.tableReservationDetails.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getBorrowingReservableItemDetails (category) {
      this.list.item_details = []
      this.fetching.item_details = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getItemDetails(`category=${category}&package=0&without_employee=1`).then(({ data }) => {
          this.list.item_details = data
          this.fetching.item_details = this.state.busy = false
          resolve(data)
        })
      })
    },

    onPreviewBorrowingItemDetail (bid, assigning = false) {
      this.state.assigning = assigning

      this.borrowing_item_detail = {
        id: bid.id,
        borrowing_id: bid.borrowing_id,
        template_id: bid.template_id,
        template_name: bid.template_name,
        category_id: bid.category_id,
        category_name: bid.category_name
      }

      this.item_detail.category_name = bid.category_name
      this.item_detail.remarks = bid.remarks
      this.item_detail.status = bid.status

      const itemDetail = bid.item_detail
      if (itemDetail) {
        this.item_detail.id = itemDetail.id
        this.item_detail.type_name = itemDetail.type_name
        this.item_detail.category_name = itemDetail.category_name
        this.item_detail.item_detail_code = itemDetail.item_detail_code
        this.item_detail.item_detail_name = itemDetail.item_detail_name
        this.item_detail.serial_number = itemDetail.serial_number
        this.item_detail.brand_name = itemDetail.brand_name
        this.item_detail.supplier_name = itemDetail.supplier_name
        this.item_detail.specifications = itemDetail.specifications
      }

      this.$bvModal.show('modal-borrowing-detail')
    },

    onCloseBorrowingItemDetail () {
      this.item_detail.id = null
      this.item_detail.type_name = null
      this.item_detail.category_name = null
      this.item_detail.item_detail_code = null
      this.item_detail.item_detail_name = null
      this.item_detail.serial_number = null
      this.item_detail.brand_name = null
      this.item_detail.supplier_name = null
      this.item_detail.specifications = null
      this.item_detail.remarks = null
      this.item_detail.status = 'Pending'

      this.list.item_details = []

      this.borrowing_item_detail = {
        id: null,
        borrowing_id: null,
        template_id: null,
        template_name: null,
        category_id: null,
        category_name: null
      }
    }

  }
}
</script>
<style>
  #modal-borrowings .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }

  #modal-borrowings .modal-content {
    min-height: 100vh;
  }
</style>
